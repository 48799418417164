import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
EyeIcon,
MapPinIcon,
ShieldCheckIcon,UsersIcon,PuzzlePieceIcon,ViewColumnsIcon,ChartBarIcon,AdjustmentsHorizontalIcon,LinkIcon,CogIcon,ArrowTrendingUpIcon


 } from '@heroicons/react/24/outline'


import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'open',
    description:
      'Mailatmars offers robust email open tracking capabilities, allowing you to monitor when recipients open your emails, providing valuable insights for campaign optimization',
    icon: EyeIcon,
  },
  {
    name: 'Click',
    description:
      'Monitor recipient interactions with your email campaign links, providing insights for optimizing engagement and driving conversions effectively',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'Location',
    description:
      'Mailatmars offers geo-location tracking within its email campaign analytics, allowing you to see where your recipients are opening emails from',
    icon: MapPinIcon,
  },
  {
    name: 'Accuracy',
    description:
      'Mailatmars ensures data accuracy in campaign analytics, providing reliable insights into opens, clicks, and engagement metrics for informed decision-making',
    icon: ShieldCheckIcon,
  }
]


const automation_builder_features = [
  {
    name: 'Multiple Trigger for same flow',
    description: 'automation supports multiple trigger nodes within the same flow, allowing flexible and dynamic automation setups.',
    icon: BoltIcon,
  },
  {
    name: 'Easy to Understand Interface',
    description: 'Our intuitive interface makes creating automations simple, even for beginners.',
    icon: UsersIcon,
  },
  {
    name: 'Drag and Drop Functionality',
    description: 'Easily build your workflows with our drag and drop functionality.',
    icon: PuzzlePieceIcon,
  },
  {
    name: 'Real-Time Workflow Visualization',
    description: 'See your automation workflows come to life in real-time.',
    icon: ViewColumnsIcon,
  },
  {
    name: 'Pre-Built Templates for Quick Setup',
    description: 'Start quickly with pre-built templates for common automation scenarios.',
    icon: ChartBarIcon,
  },
  {
    name: 'Customizable Actions and Triggers',
    description: 'Tailor your automations with customizable actions and triggers.',
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: 'Multi-Step Automation Flows',
    description: 'Create complex, multi-step workflows to cover all your needs.',
    icon: BoltIcon,
  },
  {
    name: 'Integration with Third-Party Apps',
    description: 'Seamlessly integrate with your favorite third-party apps and services.',
    icon:LinkIcon,
  },
  {
    name: 'Conditional Logic for Advanced Customization',
    description: 'Use conditional logic to create highly customized automation flows.',
    icon: CogIcon,
  },
  {
    name: 'Detailed Analytics and Reporting',
    description: 'Get detailed insights and reports on your automation performance.',
    icon: ArrowTrendingUpIcon,
  },
  {
    name: 'User-Friendly Design for Marketers of All Skill Levels',
    description: 'Designed with marketers in mind, our tool is easy for anyone to use.',
    icon: UsersIcon,
  },
];


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />


      <div className="relative isolate  pt-14 ">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div class="overflow-hidden pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
            <div className="hidden sm:mb-8 sm:flex sm:justify-left">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Streamlined Email Marketing Automation for Maximum Impact

              </div>
            </div>
              <div className="lg:max-w-lg">

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Effortless Email Marketing Automation for Success</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
              Mailatmars offers powerful email marketing automation tools designed to streamline campaigns, enhance engagement, and boost your business success effortlessly
                </p>

              </div>
              <div className="mt-10 flex items-center  gap-x-6">
                <a
                  href="https://account-v2.mailatmars.com/account/create"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a href="/solutions/" className="text-sm font-semibold leading-6 text-gray-900">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/dttmahifk/image/upload/v1727072266/Screenshot_2024-09-23_at_11.42.49_AM_avueof.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
        </div>


        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>



      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Journey Builder</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Multiple Trigger For One Automation</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Mailatmars automates your email marketing with triggers like contact and campaign actions. Customize workflows with checks, send personalized emails, and track engagement seamlessly for optimal marketing efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://res.cloudinary.com/dttmahifk/image/upload/v1727072266/Screenshot_2024-09-23_at_11.40.07_AM_pugx6a.png"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">


            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
               {automation_builder_features.map((feature) => (
                 <div key={feature.name} className="relative pl-9">
                   <dt className="inline font-semibold text-gray-900">
                     <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                   </dt>
                   <dd className="inline">{" "+feature.description}</dd>
                 </div>
               ))}
             </dl>




            </div>
          </div>
        </div>
      </div>
    </div>





      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Customer Journey Builder</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Automate Emails: Trigger, Check, Send, Track Easily</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Mailatmars automates your email marketing with triggers like contact and campaign actions. Customize workflows with checks, send personalized emails, and track engagement seamlessly for optimal marketing efficiency.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/Desktop-FeaturedNews-Card3@4x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=832&hei=357&qlt=100&fmt=png-alpha&fit=constrain"
            alt=""
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">


            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">⚡</span>
                Trigger by Contact Action
              </dt>
              <dd className="inline"> Set triggers based on contact actions such as form submissions, link clicks, and more.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">⚡</span>
                Trigger by Campaign Action
              </dt>
              <dd className="inline"> Automate actions based on campaign events like email opens and clicks.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">✔️</span>
                Check Conditions
              </dt>
              <dd className="inline"> Set conditions to customize the automation flow based on recipient behavior and attributes.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">✉️</span>
                Send Email
              </dt>
              <dd className="inline"> Automate email sends with personalized content to keep your audience engaged.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">🌐</span>
                Send Webhook
              </dt>
              <dd className="inline"> Integrate with other systems by sending webhooks as part of your automation flow.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">⏳</span>
                Wait Until
              </dt>
              <dd className="inline"> Use waiting actions to pause the automation until specific conditions are met.</dd>
            </div>
            <div className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <span className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true">📊</span>
                Track Contact Status
              </dt>
              <dd className="inline"> Easily track the status and engagement of your contacts throughout the automation process.</dd>
            </div>
          </dl>




            </div>
          </div>
        </div>
      </div>
    </div>





<Feature />


  <Pricing />

<Stats />

<Review />

    <Cta />

<Footer />
 <Helmet>
<script id="messenger-widget-b" src="https://cdn.botpenguin.com/website-bot.js" defer>665be91d38f8120275afa983,665be8fe8b01fbfc8fee51ed</script>
 </Helmet>
    </div>
  )
}
