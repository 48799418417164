import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
RectangleGroupIcon,
UsersIcon,
SparklesIcon,
RocketLaunchIcon


 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Responsive email block',
    description:
      'Incorporate responsive email blocks in Mailatmars editor to ensure your content looks great on all devices effortlessly',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Unique 5+ blocks',
    description: 'Header, product showcase, testimonial, social media integration, and footer blocks enhance brand identity and engagement in branded email templates',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Easy integrate social media',
    description: 'Effortlessly incorporate social media icons into your emails using Mailatmars intuitive email editor for enhanced engagement and connectivity.',
    icon: UsersIcon,
  },
  {
    name: 'Test in various plateform',
    description: 'Straightforward test email editor streamlines the process, facilitating quick iteration and optimization for superior campaign results',
    icon: SparklesIcon,
  },
  {
    name: 'No branding in free plan',
    description: 'Access Mailatmars email editor for free, empowering you to design and send professional campaigns without any financial commitment',
    icon: RocketLaunchIcon,
  },
]


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Mailchimp() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />

         <div className="relative isolate px-6 pt-14 lg:px-8">
           <div
             aria-hidden="true"
             className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
           >
             <div
               style={{
                 clipPath:
                   'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
               }}
               className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
             />
           </div>
           <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-24">
             <div className="hidden sm:mb-8 sm:flex sm:justify-center">

             </div>
             <div className="text-center">
               <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                 Mailatmars Vs. Sendinblue
               </h1>
               <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                Mailatmars offers intuitive design, free branding removal, and responsive email templates, surpassing Sendinblue in flexibility and cost-effectiveness.
               </p>
               <div className="mt-10 flex items-center justify-center gap-x-6">
                 <a
                   href="https://account-v2.mailatmars.com/account/create"
                   className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                 >
                   Get started
                 </a>
                 <a href="https://mailatmars.com/solutions/" className="text-sm/6 font-semibold text-gray-900">
                   Learn more <span aria-hidden="true">→</span>
                 </a>
               </div>
             </div>
           </div>
           <div
             aria-hidden="true"
             className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
           >
             <div
               style={{
                 clipPath:
                   'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
               }}
               className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
             />
           </div>
         </div>


         <div class="p-6">
           <div class="max-w-6xl mx-auto">
             <h2 class="text-lg font-semibold text-gray-900">MailatMars vs Sendinblue: Feature Comparison</h2>
             <p class="text-sm text-gray-500 mb-4">
               Comprehensive feature comparison of Mailatmars and Sendinblue for email marketing.
             </p>
             <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg">
               <table class="min-w-full divide-y divide-gray-200">
                 <thead class="bg-gray-100">
                   <tr>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Feature
                     </th>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Mailatmars
                     </th>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Sendinblue
                     </th>
                   </tr>
                 </thead>
                 <tbody class="bg-white divide-y divide-gray-200">
                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900">Batch Campaigning</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                 </tr>
                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900">Unlimited Audiance</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                 </tr>
                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900">Unlimited Contacts</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                 </tr>
                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900">Unlimited Sender Domain</td>
                   <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                 </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Drag-and-Drop Email Builder</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>

                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">No Sending limit</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Automation with Condition Logic</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Dynamic Audience Segmentation</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">24/7 Customer Support</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Advanced A/B Testing</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Real-Time Analytics</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Multi-Channel Campaign Support</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Affordable Pricing Tiers</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Customizable Templates</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">API Integration</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                   </tr>
                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">Event-Based Triggers</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>

                   <tr>
                     <td class="px-6 py-4 text-sm text-gray-900">No-Branding</td>
                     <td class="px-6 py-4 text-sm text-gray-900">✅</td>
                     <td class="px-6 py-4 text-sm text-gray-900">❌</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
         </div>



         <div class="p-6">
           <div class="max-w-6xl mx-auto">
             <h2 class="text-lg font-semibold text-gray-900">MailatMars vs Sendinblue: Pricing Comparision</h2>
             <p class="text-sm text-gray-500 mb-4">
               Mailatmars offer less spend higher return pricing plan based on your need
             </p>
             <div class="overflow-hidden shadow border border-gray-200 sm:rounded-lg">
               <table class="min-w-full divide-y divide-gray-200">
                 <thead class="bg-gray-100">
                   <tr>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Plan
                     </th>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Mailatmars
                     </th>
                     <th scope="col" class="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                       Sendinblue
                     </th>
                   </tr>
                 </thead>
                 <tbody class="bg-white divide-y divide-gray-200">
                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>Lite</b></td>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>$65</b> / 100K emails & unlimited contacts</td>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>$81</b> / 100K emails & unlimited contacts</td>
                 </tr>

                 <tr>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>Premium</b></td>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>$69</b> / 100K emails & unlimited contacts</td>
                   <td class="px-6 py-4 text-sm text-gray-900"><b>$129</b> / 100K emails & unlimited contacts</td>
                 </tr>

                 </tbody>
               </table>
             </div>
           </div>
         </div>







<Feature />


  <Pricing />

<Stats />

<Review />

    <Cta />

<Footer />
 <Helmet>
<script id="messenger-widget-b" src="https://cdn.botpenguin.com/website-bot.js" defer>665be91d38f8120275afa983,665be8fe8b01fbfc8fee51ed</script>
 </Helmet>
    </div>
  )
}
